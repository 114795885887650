(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

(function ($) {
  'use strict';

  window.DiviPixel = {};

  window.DiviPixel.get_responsive_css = function (props, property, css_selector, css_property, important) {
    var css = [];
    var responsive_active = props[property + "_last_edited"] && props[property + "_last_edited"].startsWith("on");
    var declaration_desktop = "";
    var declaration_tablet = "";
    var declaration_phone = "";
    var is_important = important ? "!important" : "";

    switch (css_property) {
      case "margin":
      case "padding":
        if (props[property]) {
          var values = props[property].split("|");
          declaration_desktop = css_property + "-top: " + values[0] + is_important + ";\n                                           " + css_property + "-right: " + values[1] + is_important + ";\n                                           " + css_property + "-bottom: " + values[2] + is_important + ";\n                                           " + css_property + "-left: " + values[3] + is_important + ";";
        }

        if (responsive_active && props[property + "_tablet"]) {
          var values = props[property + "_tablet"].split("|");
          declaration_tablet = css_property + "-top: " + values[0] + is_important + ";\n                                          " + css_property + "-right: " + values[1] + is_important + ";\n                                          " + css_property + "-bottom: " + values[2] + is_important + ";\n                                          " + css_property + "-left: " + values[3] + is_important + ";";
        }

        if (responsive_active && props[property + "_phone"]) {
          var values = props[property + "_phone"].split("|");
          declaration_phone = css_property + "-top: " + values[0] + is_important + ";\n                                         " + css_property + "-right: " + values[1] + is_important + ";\n                                         " + css_property + "-bottom: " + values[2] + is_important + ";\n                                         " + css_property + "-left: " + values[3] + is_important + ";";
        }

        break;

      default:
        //Default is applied for values like height, color etc.
        declaration_desktop = css_property + ": " + props[property] + is_important + ";";
        declaration_tablet = css_property + ": " + props[property + "_tablet"] + is_important + ";";
        declaration_phone = css_property + ": " + props[property + "_phone"] + is_important + ";";
    }

    css.push({
      selector: css_selector,
      declaration: declaration_desktop
    });

    if (props[property + "_tablet"] && responsive_active) {
      css.push({
        selector: css_selector,
        declaration: declaration_tablet,
        device: 'tablet'
      });
    }

    if (props[property + "_phone"] && responsive_active) {
      css.push({
        selector: css_selector,
        declaration: declaration_phone,
        device: 'phone'
      });
    }

    return css;
  };

  window.DiviPixel.get_border_css = function (props, suffix, border_radii_selector, border_styles_selector) {
    var css = [];

    if (suffix && '' !== suffix && 'default' !== suffix) {
      suffix = '_' + suffix;
    } else {
      suffix = '';
    }
    /** Border Radius */


    var border_radii = props["border_radii" + suffix] ? props["border_radii" + suffix].split("|") : [];
    css.push({
      selector: border_radii_selector,
      declaration: "border-top-left-radius: " + border_radii[1] + "; border-top-right-radius: " + border_radii[2] + "; border-bottom-right-radius: " + border_radii[3] + "; border-bottom-left-radius: " + border_radii[4] + ";"
    });
    /** Border Width */

    var width_top = props["border_width_top" + suffix] || props["border_width_all" + suffix];
    var width_right = props["border_width_right" + suffix] || props["border_width_all" + suffix];
    var width_bottom = props["border_width_bottom" + suffix] || props["border_width_all" + suffix];
    var width_left = props["border_width_left" + suffix] || props["border_width_all" + suffix];
    css.push({
      selector: border_styles_selector,
      declaration: "border-top-width: " + width_top + "; border-right-width: " + width_right + "; border-bottom-width: " + width_bottom + "; border-left-width: " + width_left + ";"
    });
    /** Border Style */

    var style_top = props["border_style_top" + suffix] || props["border_style_all" + suffix] || 'solid';
    var style_right = props["border_style_right" + suffix] || props["border_style_all" + suffix] || 'solid';
    var style_bottom = props["border_style_bottom" + suffix] || props["border_style_all" + suffix] || 'solid';
    var style_left = props["border_style_left" + suffix] || props["border_style_all" + suffix] || 'solid';
    css.push({
      selector: border_styles_selector,
      declaration: "border-top-style: " + style_top + "; border-right-style: " + style_right + "; border-bottom-style: " + style_bottom + "; border-left-style: " + style_left + ";"
    });
    /** Border Color */

    var color_top = props["border_color_top" + suffix] || props["border_color_all" + suffix];
    var color_right = props["border_color_right" + suffix] || props["border_color_all" + suffix];
    var color_bottom = props["border_color_bottom" + suffix] || props["border_color_all" + suffix];
    var color_left = props["border_color_left" + suffix] || props["border_color_all" + suffix];
    css.push({
      selector: border_styles_selector,
      declaration: "border-top-color: " + color_top + "; border-right-color: " + color_right + "; border-bottom-color: " + color_bottom + "; border-left-color: " + color_left + ";"
    });
    return css;
  };

  $.fn.dipi_masonry_gallery = function (params) {
    var $this = $(this);

    if (typeof $.magnificPopup === "undefined") {
      setTimeout(function () {
        $this.dipi_masonry_gallery(params);
      }, 300);
      return;
    }

    var masonry = $this.masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.grid-item',
      // use element for option
      columnWidth: '.grid-sizer',
      percentPosition: true,
      gutter: '.gutter-sizer'
    });
    $this.find('.grid-item a').magnificPopup({
      // delegate: '.et_pb_gallery_image a',
      type: 'image',
      removalDelay: 500,
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        tCounter: '%curr% / %total%'
      },
      mainClass: 'mfp-fade',
      zoom: {
        enabled: true,
        //! et_pb_custom.is_builder_plugin_used,
        duration: 500,
        opener: function opener(element) {
          return element.find('img');
        }
      },
      autoFocusLast: false,
      image: {
        verticalFit: true,
        titleSrc: function titleSrc(item) {
          var title = "";

          if (item.el.attr('data-title')) {
            title += item.el.attr('data-title');
          }

          if (item.el.attr('data-caption')) {
            title += "<small class='dipi_masonry_gallery_caption'>" + item.el.attr('data-caption') + "</small>";
          }

          return title;
        }
      }
    });
    $this.find('.grid-item a').click(function (e) {
      e.preventDefault();
      return false;
    });
    var layout = $.debounce(250, function () {
      masonry.masonry('layout');
    });

    if ($this.attr("data-lazy") === "true") {
      var observer = new MutationObserver(layout);
      var config = {
        attributes: true,
        childList: true,
        subtree: true
      };
      observer.observe($this[0], config);
    }

    masonry.imagesLoaded().progress(layout);
    return masonry.masonry('layout');
  };

  $.fn.dipi_counter = function (params) {
    var $this = $(this);
    var $this_counter = $(this).find(".dipi_counter_number_wrapper");
    var count_duration = $this_counter.attr('data-count-duration') ? $this_counter.attr('data-count-duration') : 2000;
    var count_to = $this_counter.attr('data-count-to') ? $this_counter.attr('data-count-to') : 0;
    var count_from = $this_counter.attr('data-count-from') ? $this_counter.attr('data-count-from') : 0;
    var forced_decimal_places = $this_counter.attr('data-decimal-places');
    var force_decimal_places = $this_counter.attr('data-force-decimal-places');
    var show_decimals = count_to % 1 !== 0 || count_from % 1 !== 0 || force_decimal_places === true || force_decimal_places === 'true';
    var decimal_places = force_decimal_places ? forced_decimal_places : Math.max(get_decimal_places(count_to), get_decimal_places(count_from));
    var counter_type = $this_counter.attr('data-counter-type') ? $this_counter.attr('data-counter-type') : 'number';
    var circle_percent = $this_counter.attr('data-circle-percent') ? parseInt($this_counter.attr('data-circle-percent')) : 100;
    var separator = $this_counter.attr('data-number-separator');
    var decimal_separator = $this_counter.attr('data-number-decimal-separator');
    var size = 0;
    var trackColor = false;
    var lineWidth = 0;
    var barColor = false;
    var lineCap = false;
    var scaleColor = false;
    var scaleLength = false;
    var rotate = 0; //Setup Circular counter if necessary

    if ('circle' === counter_type) {
      size = $this_counter.attr('data-circle-size') ? parseInt($this_counter.attr('data-circle-size')) : $(this).width();
      trackColor = $this_counter.attr('data-circle-track-color');
      lineWidth = Math.min($this_counter.attr('data-circle-line-width'), size);
      barColor = $this_counter.attr('data-circle-bar-color');
      lineCap = $this_counter.attr('data-circle-line-cap');
      scaleColor = $this_counter.attr('data-circle-use-scale') === 'true' ? $this_counter.attr('data-circle-scale-color') : false;
      scaleLength = $this_counter.attr('data-circle-use-scale') === 'true' ? parseInt($this_counter.attr('data-circle-scale-length')) : false;

      if (scaleLength) {
        lineWidth = Math.min($this_counter.attr('data-circle-line-width'), size - 2 * scaleLength - 4);
      }

      rotate = parseInt($this_counter.attr('data-circle-rotate')); //Enable responsive circle size if no size is set

      if (!$this_counter.attr('data-circle-size')) {
        new ResizeSensor($this, function () {
          $this.find('canvas').css('width', $this.width());
          $this.find('canvas').css('height', $this.width());
        });
      }
    }

    $this_counter.easyPieChart({
      animate: {
        duration: count_duration,
        enabled: true
      },
      //easing:      'easeOutBounce',
      size: size,
      barColor: barColor,
      trackColor: trackColor,
      // trackAlpha:  trackAlpha,
      lineWidth: lineWidth,
      lineCap: lineCap,
      scaleColor: scaleColor,
      scaleLength: scaleLength,
      rotate: rotate,
      onStart: function onStart() {},
      onStep: function onStep(from, to, percent) {
        //Normalize percent
        if ('circle' === counter_type) {
          percent = percent * 100 / circle_percent;
        }

        var number = count_from - (count_from - count_to) * (percent / 100);
        number = !show_decimals ? Math.round(parseInt(number)) : round_to_precision(number, decimal_places).toFixed(decimal_places);
        number = format_number(number, separator, decimal_separator);
        $(this.el).find('.dipi_counter_number_number').text(number);
      },
      onStop: function onStop(from, to) {
        var number = !show_decimals ? Math.round(parseInt(count_to)) : round_to_precision(count_to, decimal_places).toFixed(decimal_places);
        number = format_number(number, separator, decimal_separator);
        $(this.el).find('.dipi_counter_number_number').text(number);
      }
    });
    $this_counter.fadeIn('fast');

    if ($.fn.waypoint && 'yes' !== et_pb_custom.ignore_waypoints) {
      waypoint($this, {
        offset: '75%',
        handler: function handler() {
          $this.dipi_counter_update();
        }
      }); //Fallback

      waypoint($this, {
        offset: 'bottom-in-view',
        handler: function handler() {
          $this.dipi_counter_update();
        }
      });
    }
  };

  $.fn.dipi_counter_update = function (params) {
    var $counter = $(this).find(".dipi_counter_number_wrapper");
    var delay = parseInt($counter.attr('data-count-animation-delay'));
    setTimeout(function () {
      var counter_type = $counter.attr('data-counter-type') ? $counter.attr('data-counter-type') : 'number';

      try {
        if ('number' === counter_type) {
          $counter.data('easyPieChart').update(100);
        } else if ('circle' === counter_type) {
          var circle_percent = $counter.attr('data-circle-percent') ? $counter.attr('data-circle-percent') : 100;
          $counter.data('easyPieChart').update(circle_percent);
        }
      } catch (error) {
        console.error("Error in dipi_counter_update:", error);
      }
    }, delay);
  };

  $.fn.dipi_counter_destroy = function (params) {
    var $this = $(this);
    $this.find(".resize-sensor").remove();
    var $counter = $this.find(".dipi_counter_number_wrapper");
    var count_from = $counter.attr('data-count-from') ? $counter.attr('data-count-from') : 0;
    var number = Math.round(parseInt(count_from));
    $counter.removeData('easyPieChart');
    $counter.find('canvas').remove();
    $counter.find('.dipi_counter_number_number').text(number);
  };

  window.dipi_countdown_destroy = function (clock) {
    clock.off('update.countdown');
    clock.off('finish.countdown');
    clock.off('stop.countdown');

    if (undefined === clock.data("countdown-instance")) {
      return;
    }

    clock.countdown('remove');
    clock.empty();
  };

  window.dipi_countdown = function (clock) {
    var config = JSON.parse(clock.attr("data-config"));
    config = pluralizeLabels(config);
    config = adjustCurrentDate(config);

    if (!config.date || '' === config.date) {
      return;
    }

    var counterConfig = {};

    if ('continue' === config.finish_countdown) {
      counterConfig.elapse = true;
    }

    var components = [];

    if (config.show_weeks) {
      components.push("weeks");
    }

    if (config.show_days) {
      components.push("days");
    }

    if (config.show_hours) {
      components.push("hours");
    }

    if (config.show_minutes) {
      components.push("minutes");
    }

    if (config.show_seconds) {
      components.push("seconds");
    }

    config.components = components;
    clock.countdown(config.date, counterConfig);

    switch (config.style) {
      case 'custom_format':
        setupCustomFormat(clock, config);
        break;

      case 'block_clock':
        setupBlockClock(clock, config);
        break;

      case 'flip_clock':
      default:
        setupFlipClock(clock, config);
    }

    function addFlipClockHtml(clock, config) {
      var template = '<div class="face_wrapper">';
      config.components.map(function (component, index) {
        var label = config["label_" + component].split(",")[1];
        label = "<div class=\"label\">" + label + "</div>";
        template += "<div class=\"face face_" + component + "\">";

        if ('above' === config.clock_label_position) {
          template += label;
        }

        template += '<div class="' + ['time', component].join(' ') + '">';
        template += '<span class="count curr top">00</span>';
        template += '<span class="count next top">00</span>';
        template += '<span class="count next bottom">00</span>';
        template += '<span class="count curr bottom">00</span>';
        template += '</div>';

        if ('below' === config.clock_label_position) {
          template += label;
        }

        template += '</div>'; //face
      });
      template += '</div>'; //face_wrapper

      clock.html(template);
    }

    function addBlockClockHtml(clock, config) {
      var template = '<div class="face_wrapper">';
      config.components.map(function (component, index) {
        var label = config["label_" + component].split(",")[1];
        label = "<div class=\"label\">" + label + "</div>";
        template += "<div class=\"face face_" + component + "\">";

        if ('above' === config.clock_label_position) {
          template += label;
        }

        template += '<div class="' + ['time', component].join(' ') + '">';
        template += '00';
        template += '</div>';

        if ('below' === config.clock_label_position) {
          template += label;
        }

        template += '</div>'; //face
      });
      template += '</div>'; //face_wrapper

      clock.html(template);
    }

    function getClockFormat(components) {
      var format = '';

      if (components.includes("weeks")) {
        format += '%w:%d:%H:%M:';
      } else if (components.includes("days")) {
        format += '%D:%H:%M:';
      } else if (components.includes("hours")) {
        format += '%I:%M:';
      } else if (components.includes("minutes")) {
        format += '%N:';
      } else {
        format += '%T';
      }

      if (components.includes("seconds") && components.includes("minutes")) {
        format += '%S';
      }

      return format;
    }

    function setupFlipClock(clock, config) {
      addFlipClockHtml(clock, config);
      var format = getClockFormat(config.components);
      var currDate = '00:00:00:00:00';
      var nextDate = '00:00:00:00:00'; // Parse countdown string to an object

      function strfobj(str) {
        var parsed = str.split(":");
        var obj = {};
        config.components.forEach(function (label, i) {
          obj[label] = parsed[i];
        });
        return obj;
      } // Return the time components that diffs


      function diff(obj1, obj2) {
        var diff = [];
        config.components.forEach(function (key) {
          if (obj1[key] !== obj2[key]) {
            diff.push(key);
          }
        });
        return diff;
      }

      clock.on('update.countdown', function (event) {
        var newDate = event.strftime(format);
        var data;

        if (newDate !== nextDate) {
          currDate = nextDate;
          nextDate = newDate; // Setup the data

          data = {
            'curr': strfobj(currDate),
            'next': strfobj(nextDate)
          }; // Apply the new values to each node that changed

          diff(data.curr, data.next).forEach(function (label) {
            var selector = '.%s'.replace(/%s/, label),
                $node = clock.find(selector); // Update the node

            $node.removeClass('flip');
            $node.find('.curr').text(data.curr[label]);
            $node.find('.next').text(data.next[label]); // Wait for a repaint to then flip

            setTimeout(function ($the_node) {
              $the_node.addClass('flip');
            }, 50, $node); // Update labels
          });
        }

        updateClockLabels(clock, config, event);
      });
      setupFinishCountdown(clock, config);
    }

    function setupBlockClock(clock, config) {
      addBlockClockHtml(clock, config);
      var format = getClockFormat(config.components); // Parse countdown string to an object

      function strfobj(str) {
        var parsed = str.split(":");
        var obj = {};
        config.components.forEach(function (label, i) {
          obj[label] = parsed[i];
        });
        return obj;
      }

      clock.on('update.countdown', function (event) {
        var date = event.strftime(format);
        var data = strfobj(date);
        config.components.forEach(function (label) {
          var $node = clock.find("." + label);
          $node.text(data[label]);
        });
        updateClockLabels(clock, config, event);
      });
      setupFinishCountdown(clock, config);
    }

    function setupCustomFormat(clock, config) {
      if (!config.custom_format) {
        return;
      }

      clock.on('update.countdown', function (event) {
        $(this).html(event.strftime(config.custom_format));
      });
      setupFinishCountdown(clock, config);
    }

    function setupFinishCountdown(clock, config) {
      clock.on('finish.countdown', function (event) {
        if (['stop', 'forward', 'script', 'html'].includes(config.finish_countdown)) {
          $(this).parent().addClass('disabled');

          switch (config.style) {
            case 'flip_clock':
              clock.find(".count").text("00");
              break;

            case 'block_clock':
              clock.find(".time").text("00");
              break;

            case 'custom_format':
              clock.html(event.strftime(config.custom_format));
              break;
          }
        }

        if ('forward' === config.finish_countdown && !window.et_builder_version) {
          window.location.replace(config.forwarding_url);
        }

        if ('script' === config.finish_countdown && !window.et_builder_version) {
          eval(config.script);
        }

        if ('html' === config.finish_countdown) {
          clock.html(config.html);
        }
      });
    }

    function updateClockLabels(clock, config, event) {
      var $times = clock.find(".face .time");
      $times.each(function () {
        var $this = $(this);
        var format;

        if ($this.hasClass("weeks")) {
          format = "%!w:" + config["label_weeks"] + ";";
        }

        if ($this.hasClass("days")) {
          format = "%!d:" + config["label_days"] + ";";
        }

        if ($this.hasClass("hours")) {
          format = "%!H:" + config["label_hours"] + ";";
        }

        if ($this.hasClass("minutes")) {
          format = "%!M:" + config["label_minutes"] + ";";
        }

        if ($this.hasClass("seconds")) {
          format = "%!S:" + config["label_seconds"] + ";";
        }

        $this.siblings(".label").text(event.strftime(format));
      });
    }

    function pluralizeLabels(config) {
      Object.entries(config).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        if (key.startsWith("label_") && !value.includes(",")) {
          config[key] = value + "," + value;
        }
      });
      return config;
    }

    function adjustCurrentDate(config) {
      if (config['date'] !== 'now') {
        return config;
      }

      if (config['use_cookie'] === true) {
        var cookie = getCookie(config['cookie_id']);

        if (cookie && '' !== cookie) {
          config['date'] = cookie;
          return config;
        }
      }

      var now = new Date();
      now.setTime(now.getTime() + config['offset'] * 1000);
      var year = now.getFullYear().toString();
      var month = (now.getMonth() + 1).toString().padStart(2, '0');
      var day = now.getDate().toString().padStart(2, '0');
      var hour = now.getHours().toString().padStart(2, '0');
      var minute = now.getMinutes().toString().padStart(2, '0');
      var second = now.getSeconds().toString().padStart(2, '0');
      var dateString = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      config['date'] = dateString;

      if (config['use_cookie'] === true) {
        document.cookie = config['cookie_id'] + "=" + dateString;
      }

      return config;
    }

    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    }
  };

  function get_decimal_places(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    if (!match) {
      return 0;
    }

    return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
  }

  function round_to_precision(number, precision) {
    if (number == 0) return 0;
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }

  ;

  function format_number(number_value, separator, decimal_separator) {
    var numberString = number_value.toString();

    if (decimal_separator && decimal_separator !== '') {
      numberString = numberString.replace(".", decimal_separator);
    }

    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }

  function waypoint($element, options) {
    if (!$element.data('et_waypoint')) {
      var instances = $element.waypoint(options);

      if (instances && instances.length > 0) {
        $element.data('et_waypoint', instances[0]);
      }
    } else {
      $element.data('et_waypoint').context.refresh();
    }
  }

  $(document).ready(function () {
    // window.DiviPixel.fix_et_db_et_boc_body_css();
    // $(".dipi_bucket .dipi_bucket_link").dipi_hover_link_double_tap();
    // Setup Masonry Galleries
    $(".dipi_masonry_gallery").each(function () {
      $("." + $(this).attr("class").replace(/ /g, ".") + " .grid").dipi_masonry_gallery();
    }); // Setup Counters

    $('.dipi_counter').each(function () {
      var $this = $(this);
      $this.dipi_counter();
    }); //Don't fire in the VB

    if (!window.et_builder_version) {
      $(".dipi_countdown .clock").each(function () {
        window.dipi_countdown($(this));
      });
    }
  });
})(jQuery);

},{}]},{},[1]);
